import clsx from 'clsx'
import debug from 'debug'
import { Link, useLocation, useParams } from 'react-router-dom'
import Icon from 'src/components/icons/icon'
import { useGetUni } from 'src/utils/hooks/use-get-uni'

const logger = debug('app:side-nav-update')

export interface LinkItem {
  path: string
  label: string
  iconName: string
  isActive: boolean
  // Used when extra paths are nested under the main path
  additionalMatchingPaths?: string[]
}

export function UniSideNav() {
  const { id: uniName, node } = useParams()
  const getUniQuery = useGetUni()
  const uni = getUniQuery?.data?.getUni
  const nodeZero = uni?.nodes?.[0]
  const nodeName = node || nodeZero?.name
  const basePath = `/uni/${uniName}/${nodeName}/`
  const links: LinkItem[] = [
    {
      path: `entities`,
      label: 'Data View',
      iconName: 'sidenav-data-view',
      isActive: false,
    },
    {
      path: `analyze`,
      label: 'Analyze',
      iconName: 'sidenav-analyze',
      isActive: false,
      additionalMatchingPaths: ['transactions'],
    },
    {
      path: 'share',
      label: 'Share',
      iconName: 'sidenav-handshake',
      isActive: false,
    },
    {
      path: 'workflows',
      label: 'Connectors',
      iconName: 'sidenav-connectors',
      isActive: false,
    },
    {
      path: `files`,
      label: 'Files',
      iconName: 'sidenav-folder-open',
      isActive: false,
    },
    {
      path: `settings`,
      label: 'Settings',
      iconName: 'sidenav-settings-gear',
      isActive: false,
    },
  ]
  const location = useLocation()
  const linkListItems = links?.map((link) => {
    const path = `${basePath}${link.path}`
    // Normalize trailing slash and compare to find active path
    const currentPath = location.pathname.replace(/\/+$/, '')
    const currentLinkPath = path.replace(/\/+$/, '')
    // "loose match" for entity explorer (see old sidenav)
    const isActive =
      currentPath.includes(currentLinkPath) ||
      (link.additionalMatchingPaths?.some((p) => currentPath.includes(`/${p}`)) ?? false)
    link.isActive = isActive
    link.path = path
    return <LinkItem key={path} linkItem={link} />
  })
  if (!nodeName) {
    return null
  }
  return (
    <div className={'flex-none bg-white px-4 pt-8'}>
      <ul className='flex flex-col gap-12'>{linkListItems}</ul>
    </div>
  )
}

function LinkItem({ linkItem }: { linkItem: LinkItem }) {
  const { path, label, iconName, isActive } = linkItem
  return (
    <Link to={path} aria-label={label}>
      <li
        className={clsx(
          isActive && 'bg-fuchsia-50 font-bold text-fuchsia-700',
          'flex flex-col items-center justify-center rounded-lg p-2 transition-colors duration-300 hover:bg-fuchsia-50 hover:text-fuchsia-700',
        )}
      >
        <div className=''>
          <Icon name={iconName} size={48} className={clsx(isActive && 'text-fuchsia-700')} />
        </div>
        <div className='text-xs font-semibold'>{label}</div>
      </li>
    </Link>
  )
}
