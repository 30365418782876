import * as Tooltip from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { ReactElement } from 'react'

function HoverableTooltip({
  disabled = false,
  contentClassName,
  content,
  asChild,
  autoMaxWidth,
  ...props
}: {
  content: ReactElement<any, any> | string
  disabled?: boolean
  contentClassName?: string
  className?: string
  asChild?: boolean
  autoMaxWidth?: boolean
} & Tooltip.TooltipProps &
  Pick<Tooltip.TooltipContentProps, 'side' | 'sideOffset' | 'align' | 'avoidCollisions'>) {
  const { side, sideOffset, align, avoidCollisions, children, className, ...rest } = props
  if (disabled) {
    return <>{children}</>
  }
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={50} {...rest}>
        <Tooltip.Trigger className={clsx('flex', className)} asChild={asChild}>
          {children}
        </Tooltip.Trigger>
        <Tooltip.Content
          side={side}
          sideOffset={sideOffset}
          align={align}
          avoidCollisions={avoidCollisions}
          // Nice for left/right side tooltips that might bump into viewport edge, especially if
          //  you really prefer to stay on the specified side
          style={autoMaxWidth ? { maxWidth: 'calc(var(--radix-tooltip-content-available-width) - 40px)' } : {}}
          className={clsx(
            'data-[state=closed]:animate-fadeOut z-50 whitespace-nowrap rounded-md bg-white p-1 text-xs shadow-xl',
            contentClassName,
          )}
        >
          {content}
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default HoverableTooltip
