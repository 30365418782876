import { useQuery } from 'react-query'

import { ENTERPRISE_TIERS, Tier } from '../subscription'
import { useGetOrg } from './use-get-org'

// Simple logic but will likely change in the future so keep it all in one place

// NOTE: All users are TECHNIALLY org members of their personal Org, but we only want
// to show Orgs features in the UI to users who are members of an Enterprise Org (or possibly
// non-enterprise orgs in the future, who knows, but certainly not for individual users)

export const useIsEnterpriseOrgMember = () => {
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization
  return useQuery<any, Error, boolean>(['isOrgMember', org?.orgId], async () => {
    return new Promise((resolve) => {
      // Updated logic - if your Org tier is in the ENTERPRISE_TIERS list, you're a "Vendia Orgs" member
      // It's just a matter of how far along your are in your Org setup journey...
      // Org.onboardingStatus can be used to determine how far along the user is, what UI elements to hide/show, etc.
      if (ENTERPRISE_TIERS.includes(org?.subscription?.tier as Tier)) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  })
}
