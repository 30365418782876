import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { Fragment, Key, SyntheticEvent, useRef } from 'react'
import { Control, useController, useForm } from 'react-hook-form'
import Icon from 'src/components/icons/icon'

const logger = debug('app:listbox-input')

// UPDATE NOTE: most of our older usages store the "value" in react-hook-form as an array with a single ListOption object
//  - This has led to a lot of verbose code to wrap/unwrap values to and from ListOption objects.
//  - Values can now be tracked as JUST the string/null value, and the component will handle wrapping/unwrapping internally as needed.
//  - If passing in value/onChangeHandler and using without react-hook-form, you'll continue to recieve the full ListOption object and need
//    to do _unwrapping_ yourself, but pretty simple in that case.

// Generic so you can type the value if it's something other than a string
export interface ListOption<ValueType = string | null> {
  value: ValueType
  label: string | JSX.Element
  description?: string
  disabled?: boolean
  divider?: boolean
}

type ListboxInputProps = {
  name: string
  listOptions: ListOption[]
  label?: string
  // Put small label _inside_ the input (above the selected item). Only works with single select for now.
  nestedLabel?: string
  labelDescription?: string
  placeholder?: string
  multiple?: boolean
  control?: Control<any>
  rules?: Record<string, any>
  disabled?: boolean
  portal?: boolean
  className?: string
  optionsClassName?: string
  style?: Record<string, any>
  defaultValue?: ListOption[]
  compact?: boolean
  // Pass in value and onChangeHandler to use without react-hook-form
  value?: string | null
  onChangeHandler?: (newItem: ListOption) => void
}

const ListboxInput = ({
  name,
  label,
  nestedLabel,
  labelDescription,
  placeholder,
  listOptions,
  multiple,
  control,
  rules,
  disabled,
  className,
  optionsClassName,
  style,
  defaultValue,
  value,
  onChangeHandler,
  compact = false,
  portal = true,
}: ListboxInputProps) => {
  if (control && (value || onChangeHandler)) {
    console.error('Do not pass in value or onChangeHandler when using react-hook-form!')
  }
  if (!control && !(value || onChangeHandler)) {
    console.error('You must pass in value and onChangeHandler OR pass in control when using with react-hook-form!')
  }

  // Pass in name/value/onChangeHandler to use without react-hook-form
  // Supply a dummy "control" object in this case!
  const { control: dummyControl } = useForm()
  const { field, fieldState } = useController({ name, control: control ?? dummyControl, rules, defaultValue })

  // Track the width of the button to set the width of the options list (when using portal)
  const buttonRef = useRef<HTMLDivElement>(null)

  let selectedItems: ListOption[] = []
  if (value !== undefined) {
    // If not using react-hook-form, use value prop
    // logger(name, 'selectedItems: value !== undefined', selectedItems)
    selectedItems = [listOptions.find((o) => o.value === value) as ListOption]
  } else if (typeof field.value === 'string' || field.value === null) {
    // Allow storing value as just the string/null value rather than ListOption array of objects (much simpler!)
    // logger(name, 'selectedItems: typeof field.value === string || field.value === null', selectedItems)
    const matchingItem = listOptions.find((o) => o.value === field.value) as ListOption
    selectedItems = matchingItem ? [matchingItem] : [] // Small band-aid for weird case when using legacy value as ListOption[], but value becomes null briefly on unmount
  } else if (Array.isArray(field.value) && multiple) {
    // If using "multiple" prop, so look up each value in the listOptions array
    // logger(name, 'selectedItems: Array.isArray(field.value) && multiple', selectedItems)
    selectedItems = field.value.map((v) => listOptions.find((o) => o.value === v) as ListOption)
  } else {
    // Otherwise, support legacy value in ListOption[] format
    // logger(name, 'selectedItems: else', selectedItems)
    selectedItems = field.value as ListOption[]
  }

  const { onChange: updateReactHookFormValue } = field
  const { error } = fieldState
  const handleChange = (newItems: ListOption | ListOption[]) => {
    logger(name, 'handleChange:', JSON.stringify(newItems, null, 2))
    // Legacy support for single select with value stored as ListOption object
    // If not using "multiple" AND _current_(aka previous) value is tracked as an array of single ListOption object, keep it that way
    if (!multiple && Array.isArray(field.value)) {
      updateReactHookFormValue([newItems])
      return
    }
    if (!multiple && !Array.isArray(newItems)) {
      updateReactHookFormValue(newItems.value)
      return
    }
    // When using "multiple" prop, we always store the raw values rather than wrapping them in ListOption objects
    // @ts-ignore will always be an array if we get here
    updateReactHookFormValue(newItems.map((item) => item.value))
  }

  const removeItem = (itemToRemove: ListOption) => (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    updateReactHookFormValue(
      selectedItems?.filter((item) => item.value !== itemToRemove.value).map((item) => item.value),
    )
  }

  return (
    <Listbox
      value={selectedItems}
      by='value' // Value matching based on listoption.value rather than needing to be actual same object reference
      // @ts-ignore typing almost correct
      onChange={onChangeHandler ?? handleChange}
      disabled={disabled}
      multiple={multiple}
      portal={portal}
    >
      {({ open }) => (
        <div className={className} style={style}>
          {label && (
            <Label
              className={clsx({
                'block text-sm font-semibold': true,
                'text-black': !disabled,
                'text-gray-400': disabled,
              })}
            >
              {label}
            </Label>
          )}
          {labelDescription && (
            <span id={`${name}-description`} className='text-neutral-8 block text-balance text-xs'>
              {labelDescription}
            </span>
          )}
          <div className={`relative ${compact ? '' : 'mt-1'}`} ref={buttonRef}>
            <ListboxButton
              aria-describedby={`${name}-description`}
              className={clsx(
                'relative w-full cursor-default rounded-md border bg-white pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 sm:text-sm',
                {
                  'border-error-8 focus:border-error-5 focus:ring-error-5': error,
                  'cursor-not-allowed border-gray-400 text-gray-400': disabled,
                  'border-black focus:border-gray-500 focus:ring-gray-500': !error && !disabled,
                  'min-h-[44px] py-2': !compact,
                  'max-h-[44px]': !compact && !multiple,
                  'h-[34px] max-h-[34px] pb-2 pt-1.5': compact,
                },
              )}
            >
              <div className='truncated flex flex-wrap gap-1'>
                {!selectedItems?.length && (placeholder || nestedLabel) && (
                  <span className='text-gray-500'>{nestedLabel || placeholder}</span>
                )}
                {multiple ? (
                  <div>
                    {nestedLabel && selectedItems?.length > 0 && (
                      <span className={'-mb-0.5 -mt-1 block bg-transparent text-xs text-gray-500'}>{nestedLabel}</span>
                    )}
                    <div className='mt-2 flex gap-1'>
                      {selectedItems?.map((currentSelected) => (
                        <div
                          tabIndex={-1}
                          onClick={removeItem(currentSelected)}
                          aria-label={`Remove selected option ${currentSelected.label} from ${label}`}
                          key={currentSelected.value as Key}
                        >
                          <div className='bg-information-0 hover:bg-information-1 text-information-9 border-information-3 flex items-baseline rounded-md border px-2 py-1 text-xs transition-all'>
                            <span className='flex items-center justify-center gap-2 whitespace-nowrap'>
                              {currentSelected.label}
                              <Icon name='close' size={10} />
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    {nestedLabel && selectedItems?.length > 0 && (
                      <span className={'-mb-0.5 -mt-1 block bg-transparent text-xs text-gray-500'}>{nestedLabel}</span>
                    )}
                    <span className={clsx({ 'block truncate': true, 'font-semibold text-gray-700': nestedLabel })}>
                      {selectedItems?.[0]?.label}
                    </span>
                  </div>
                )}
              </div>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <Icon name='chevron-down' size={18} style={{ color: disabled ? 'rgb(156, 163, 175)' : '#000' }} />
              </span>
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <ListboxOptions
                anchor='bottom start'
                className={clsx(
                  'border-neutral-4 absolute z-10 max-h-60 overflow-auto rounded-md border bg-white text-base shadow-2xl focus:outline-none sm:text-sm',
                  optionsClassName,
                )}
                style={{ minWidth: buttonRef.current?.offsetWidth }}
              >
                {listOptions.map((option, i) => {
                  if (option.divider) {
                    return <hr className='my-2 w-full' key={i} />
                  }
                  const isSelected = selectedItems?.some((selectedOption) => selectedOption.value === option.value)
                  return (
                    <ListboxOption
                      key={option.value}
                      className={({ focus }) =>
                        clsx({
                          'bg-information-0': focus,
                          'relative w-full cursor-default select-none py-2 pl-2 pr-9': true,
                          'text-gray-900': !option.disabled,
                          'text-gray-400': option.disabled,
                        })
                      }
                      value={option}
                      disabled={option.disabled}
                    >
                      <>
                        <div className='flex flex-col gap-1'>
                          <span className='block truncate text-sm'>{option.label}</span>
                          <span className='text-neutral-8 block text-xs'>{option.description}</span>
                        </div>
                        {isSelected ? (
                          <span className='text-information-9 absolute inset-y-0 right-0 flex items-center pr-4'>
                            <Icon name='success' size={18} aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    </ListboxOption>
                  )
                })}
              </ListboxOptions>
            </Transition>
          </div>
          {error && (
            <div className='flex gap-1 py-1 text-xs'>
              <Icon name='error' size='xs' className='' />
              {error.message}
            </div>
          )}
        </div>
      )}
    </Listbox>
  )
}

export default ListboxInput
