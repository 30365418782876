// @ts-strict-ignore
import debug from 'debug'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useGetOrg } from 'src/utils/hooks/use-get-org'

import { userState } from '../state'
import { Tier } from '../subscription'
import useGetCurrentVendiaUserQuery from './use-current-vendia-user-query'

const logger = debug('app:useFeatureToggle')

interface User {
  email: string
}

export enum Features {
  API_CREDENTIALS = 'API_CREDENTIALS',
  WAREHOUSE_EXPLORER = 'WAREHOUSE_EXPLORER',
  WORKFLOWS = 'WORKFLOWS',
  REVOPS = 'REVOPS',
  DEV_SETTINGS = 'DEV_SETTINGS',
  VINNIE = 'VINNIE',
}

interface FeatureConditions {
  allowedUsers?: string[]
  deniedUsers?: string[]
  allowedTiers?: Tier[]
  deniedTiers?: Tier[]
}

type FeaturesData = Record<Features, FeatureConditions>

const FEATURES_DATA: FeaturesData = {
  API_CREDENTIALS: {
    allowedTiers: [Tier.ENTERPRISE, Tier.ENTERPRISE_TRIAL],
  },
  WAREHOUSE_EXPLORER: {
    allowedUsers: [],
  },
  WORKFLOWS: {
    allowedTiers: [Tier.ENTERPRISE, Tier.ENTERPRISE_TRIAL],
  },
  REVOPS: {
    allowedUsers: ['*@vendia.com'],
  },
  DEV_SETTINGS: {
    allowedUsers: ['*@vendia.com'],
  },
  VINNIE: {
    allowedUsers: [],
  },
}

declare global {
  interface Window {
    enableAllFeatures: boolean
  }
}

const useFeatureToggle = (feature: Features): { isEnabled: boolean; isLoading: boolean } => {
  const [enableAllFeatures, setEnableAllFeatures] = useState(null)
  const [featureToggleState, setFeatureToggleState] = useState({ isEnabled: false, isLoading: true })
  const currentUser = useRecoilValue(userState) as User
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const getOrg = useGetOrg()
  const currentTier = getOrg?.data?.getOrganization?.subscription?.tier
  const matchingData = FEATURES_DATA[feature]
  // Some rudimentary dev tooling - turn this idea into little button/shelf like react query?
  // Just set boolean to true/false in js console to flip on/off for easier testing
  useEffect(() => {
    if (!import.meta.env.DEV) {
      return
    }
    if (window.enableAllFeatures === true && enableAllFeatures !== true) {
      setEnableAllFeatures(true)
      logger('Enabling all features')
      return
    }
    if (window.enableAllFeatures === false && enableAllFeatures !== false) {
      setEnableAllFeatures(false)
      logger('Disabling all features')
      return
    }
    if (window.enableAllFeatures == null && enableAllFeatures != null) {
      setEnableAllFeatures(null)
      logger('No enableAllFeatures flag set')
    }
  }, [window.enableAllFeatures])
  /* --- Rudimentary dev tooling ends here --- */

  useEffect(() => {
    function getFeatureToggleState() {
      if (!currentUser || !currentTier) {
        return {
          isLoading: true,
          isEnabled: false,
        }
      }
      if (enableAllFeatures === true) {
        return {
          isLoading: false,
          isEnabled: true,
        }
      }
      if (enableAllFeatures === false) {
        return {
          isLoading: false,
          isEnabled: false,
        }
      }
      if (matchingData.deniedTiers) {
        const hasDeniedTier = matchingData.deniedTiers.some((tier) => tier === currentTier)
        if (hasDeniedTier) {
          return {
            isLoading: false,
            isEnabled: false,
          }
        }
      }
      if (matchingData.deniedUsers) {
        const hasDeniedUser = matchingData.deniedUsers.some(
          (user) => user?.toLowerCase() === currentUser.email?.toLowerCase(),
        )
        if (hasDeniedUser) {
          return {
            isLoading: false,
            isEnabled: false,
          }
        }
      }
      if (matchingData.allowedUsers) {
        const hasAllowedUser = matchingData.allowedUsers.some((user) => {
          const hasWildcard = user.split('@')[0] === '*'
          if (hasWildcard) {
            return user.split('@')[1].toLowerCase() === currentUser.email?.split('@')?.[1]?.toLowerCase()
          }
          return user?.toLowerCase() === currentUser.email?.toLowerCase()
        })
        if (hasAllowedUser) {
          return {
            isLoading: false,
            isEnabled: true,
          }
        }
      }
      if (matchingData.allowedTiers) {
        const hasAllowedTier = matchingData.allowedTiers.some((tier) => tier === currentTier)
        if (hasAllowedTier) {
          return {
            isLoading: false,
            isEnabled: true,
          }
        }
      }
      return {
        isLoading: false,
        isEnabled: false,
      }
    }
    setFeatureToggleState(getFeatureToggleState())
  }, [currentUser, currentTier, enableAllFeatures])
  return featureToggleState
}

export default useFeatureToggle
