import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Role } from '@vendia/management-api-types'
import { debug } from 'debug'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Icon from 'src/components/icons/icon'
import UserIcon from 'src/components/icons/user-icon'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import useFeatureToggle, { Features } from 'src/utils/hooks/use-feature-toggle'
import { useGetOrg } from 'src/utils/hooks/use-get-org'
import { useIsEnterpriseOrgMember } from 'src/utils/hooks/use-is-org-member'
import notify from 'src/utils/notify'
import { selectedRoleState } from 'src/utils/state'
import { getTierDisplayName, Tier } from 'src/utils/subscription'

const logger = debug('app:UserDropdown')

export function UserDropdown() {
  const { isEnabled } = useFeatureToggle(Features.DEV_SETTINGS)
  const { data: isOrgMember } = useIsEnterpriseOrgMember()
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const getOrg = useGetOrg()
  const user = getCurrentVendiaUserQuery?.data?.getUser
  const subscriptionTier = getOrg?.data?.getOrganization?.subscription?.tier as Tier

  const lastNotificationRef = useRef(null)
  const [selectedRole, setSelectedRole] = useRecoilState(selectedRoleState)
  const roles = user?.roles as Role[] | undefined
  roles?.sort((a, b) => {
    // Always put "default" at the top
    if (a.isDefault || a.name === 'default') {
      return -1
    }
    if (b.isDefault || b.name === 'default') {
      return 1
    }
    return 0
  })

  async function handleSelectRole(role: Role) {
    logger('handleSelectRole', role.name)
    // Set role in local storage so it persists across page refreshes
    localStorage.setItem(`selectedRoleName:${user?.userId}`, role.name)

    // A tiny bit of delay to make extra sure item is set in local storage before we switch roles in app state
    // State update will trigger refetch of queries with selected role in query key and use-api will grab selected
    //  role from local storage - see use use-api.js for more details,
    await new Promise((resolve) => setTimeout(resolve, 150))
    setSelectedRole({ name: role.name })

    // Note: whether using useRef or useState, this dismissal doesn't work the first time for whatever reason but it's just a minor UX improvement
    // so not spending time to figure out the problem (just feels nicer to dismiss last toast rather than stack them when switching roles quickly)
    notify.dismiss(lastNotificationRef.current)
    await new Promise((resolve) => setTimeout(resolve, 500))
    lastNotificationRef.current = notify.success(`All actions will now be performed using your ${role.name} role`)
  }

  const menuItemClasses = 'block px-4 py-4 text-xs font-bold data-[focus]:bg-information-1'

  return (
    <div data-testid='user-dropdown'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <MenuButton disabled={user?.email === undefined} data-testid='user-nav'>
            <UserIcon user={user!} isInteractive />
          </MenuButton>
        </div>
        <MenuItems
          className='border-neutral-4 bg-uibg-1 absolute -right-0 z-10 mt-2 origin-top-right rounded-md border shadow-2xl transition duration-200 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0'
          transition
        >
          <div className='border-neutral-4 flex justify-between gap-8 rounded-t-md border-b p-6'>
            <div className='flex items-center gap-2'>
              <UserIcon user={user!} ignoreTheme />
              <div className='max-w-xs text-xs'>
                {user?.firstName && (
                  <div className='truncate font-bold'>
                    {user?.firstName} {user?.lastName}
                  </div>
                )}
                <div className='truncate'>{user?.email}</div>
              </div>
            </div>
            <div className='flex items-center overflow-hidden whitespace-nowrap text-xs uppercase text-gray-500'>
              {getTierDisplayName(subscriptionTier)}
            </div>
          </div>
          <div className='rounded-b-md bg-white'>
            {isOrgMember && (
              <MenuItem>
                <Link to='/org/overview' className={menuItemClasses}>
                  <div>My Organization</div>
                </Link>
              </MenuItem>
            )}
            <MenuItem>
              <Link to='/settings' className={menuItemClasses}>
                <div>Account Settings</div>
              </Link>
            </MenuItem>
            <MenuItem>
              <a
                href='https://www.vendia.com/docs/share'
                target='_blank'
                rel='noopener noreferrer'
                className={menuItemClasses}
              >
                <div>
                  <div className='flex items-center'>
                    <span>Documentation</span>
                    <Icon size={'xs'} className='ml-2' name='external' />
                  </div>
                </div>
              </a>
            </MenuItem>
            {isEnabled && (
              <MenuItem>
                <Link to='/developer' className={menuItemClasses}>
                  Internal Developer Settings
                </Link>
              </MenuItem>
            )}
            {roles && roles?.length > 1 ? (
              <div>
                <div className='border-neutral-3 text-neutral-7 flex border-b bg-white px-4 pb-2 pt-5 text-xs font-semibold'>
                  Role Selection
                </div>
                <div className='flex max-h-72 flex-col overflow-auto bg-white'>
                  {roles?.map((role) => {
                    const selected = selectedRole?.name === role.name
                    return (
                      <MenuItem key={role.name}>
                        <button
                          className={`data-[focus]:bg-information-1 flex w-full items-center justify-between gap-2 p-3 px-6`}
                          onClick={() => handleSelectRole(role)}
                        >
                          <div className='ml-2 flex max-w-md items-baseline truncate text-xs font-semibold'>
                            {role.name}
                          </div>
                          <div className='mr-2 h-5'>
                            {selected ? (
                              <Icon name='check' size={16} stroke='blue' data-testid='selected-role-check' />
                            ) : null}
                          </div>
                        </button>
                      </MenuItem>
                    )
                  })}
                </div>
              </div>
            ) : null}
            <MenuItem>
              <Link to='/logout' className={`${menuItemClasses} border-neutral-3 bg-uibg-1 rounded-b-md border-t`}>
                <div>Sign out</div>
              </Link>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </div>
  )
}
