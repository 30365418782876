import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Popover,
  PopoverButton,
  PopoverPanel,
  useClose,
} from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Icon from 'src/components/icons/icon'
import PageLoader from 'src/components/loaders/page-loader'
import useListUnis, { ListUnisItem } from 'src/utils/hooks/use-list-unis'

const logger = debug('app:RoleSwitcher')
export const UNI_SELECT_ID = 'uni-select-dropdown'

// Weird combo of Popover and Combobox to get the desired layout since we want the combobox input nested within the panel
// Thus combobox is marked as "static" and always open, also has autofocus so user can type immediately

export function UniDropdown() {
  useListUnis() // prefetch here so user doesn't have to wait when opening dropdown
  const { id: uniName, node: nodeName } = useParams()

  return (
    <div data-testid='uni-switcher'>
      <Popover className='relative inline-block text-left'>
        <PopoverButton id={UNI_SELECT_ID} className={'focus:outline-none'}>
          <div className={clsx(`text-purple-1300 hover:text-neutral-11 flex h-11 items-center gap-1`)}>
            {uniName && nodeName ? (
              <div className='mr-0.5 flex flex-col items-end justify-start'>
                <div className='text-xs'>{uniName}</div>
                <div className='text-sm font-bold'>{nodeName}</div>
              </div>
            ) : (
              <div className='text-sm font-bold'>{uniName ?? 'Universal Applications'}</div>
            )}
            <Icon name='caret-down' size={'xs'} />
          </div>
        </PopoverButton>
        <PopoverPanel
          transition
          className='transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0'
        >
          <UniDropdownCombobox />
        </PopoverPanel>
      </Popover>
    </div>
  )
}

interface Option {
  // TODO: will need a way to identify lakehouse unis from just listUnis (prefix uni name once we add alias?)
  type: 'node' | 'lakehouse-uni' | 'create-uni'
  uni: ListUnisItem
  node: ListUnisItem['nodes'][0]
}

function UniDropdownCombobox() {
  const close = useClose()
  const { listUnisQuery } = useListUnis()
  const unis = listUnisQuery?.data
  const navigate = useNavigate()
  const { id: uniName, node: nodeName } = useParams()

  const [query, setQuery] = useState('')

  // TODO: for testing stuff out, will rely on uni SKU or getting all share apps before shipping
  const lakehouseUnis = [
    'test-data-products.unis.vendia.com',
    'test-lakehouse-fix-accounts.unis.vendia.com',
    'test-role-fix.unis.vendia.com',
    'test-products-with-nodes.unis.vendia.com',
  ]

  const filteredNodeOptions =
    unis
      ?.map((uni) => {
        const uniNameMatch = uni.name.toLowerCase().includes(query.toLowerCase())
        return {
          name: uni.name,
          nodeOptions: uni.nodes
            .filter((node) => {
              // If node is deleted, don't show it!
              if (node.status === 'DESTROYED') {
                return false
              }
              // Match uni OR node name - if uni name matches, show ALL the nodes
              if (uniNameMatch) {
                return true
              }
              return node.name.toLowerCase().includes(query.toLowerCase())
            })
            .map((node) => {
              return {
                type: 'node',
                uni,
                node,
              } as Option
            }),
        }
      })
      .filter((uni) => uni.nodeOptions.length > 0) ?? []

  const handleChangeOption = async (option: Option) => {
    logger('handleChangeOption', option)
    if (!option) {
      return
    }
    if (option.type === 'create-uni') {
      navigate('/uni/create')
      close()
      return
    }
    if (option.type === 'lakehouse-uni') {
      navigate(`/uni/${option.uni.name}`)
      close()
      return
    }
    navigate(`/uni/${option.uni.name}/${option.node.name}/entities`)
    close()
  }
  return (
    <Combobox as='div' onChange={handleChangeOption} onClose={() => setQuery('')}>
      <div className='border-neutral-4 absolute -right-0 z-10 mt-2 min-w-96 origin-top-right rounded-md border bg-white shadow-2xl'>
        <div className='border-neutral-4 bg-uibg-1 flex flex-col gap-2 rounded-t-md border-b p-4'>
          {/* <div className='max-w-xs text-sm font-bold'>Applications</div> */}
          <div className='flex items-center'>
            <Icon name='search' size={16} className='mr-1 opacity-30' />
            <ComboboxInput
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder='Search Universal Applications'
              className={clsx(
                'bg-uibg-1 min-h-[40px] w-full items-center rounded-md border-none p-2 focus:outline-none',
              )}
              aria-label='Application selector'
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>
        </div>
        <ComboboxOptions static>
          <div className='max-h-[600px] overflow-y-auto rounded-b-md bg-white'>
            {listUnisQuery.isLoading && <PageLoader />}
            {!listUnisQuery.isLoading && filteredNodeOptions.length === 0 && (
              <div className='text-balance px-4 py-16 text-center text-xs'>
                {query ? (
                  <span className='font-bold'>No applications matching "{query}"</span>
                ) : (
                  <>
                    <p>You haven't created any applications. </p>Click <strong>Create Universal Application</strong>{' '}
                    below to get started!
                  </>
                )}
              </div>
            )}
            {filteredNodeOptions?.map((uni) => {
              if (lakehouseUnis.includes(uni.name)) {
                const selected = uni.name === uniName
                return (
                  <div className='flex flex-col' key={uni.name}>
                    <div className='border-neutral-2 flex flex-col border-b text-xs font-semibold'>
                      <ComboboxOption
                        value={{
                          type: 'lakehouse-uni',
                          uni: uni,
                        }}
                        className={`data-[focus]:bg-information-1 border-neutral-2 flex w-full cursor-pointer items-center justify-between gap-2 border-t p-3 px-4`}
                      >
                        <div>{uni.name}</div>
                        <div className='mr-2'>{selected ? <Icon name='check' size={16} stroke='blue' /> : null}</div>
                      </ComboboxOption>
                    </div>
                  </div>
                )
              }
              return (
                <div className='flex flex-col' key={uni.name}>
                  <div className='flex max-w-md items-baseline truncate px-4 pb-2 pt-6 text-xs'>{uni.name}</div>
                  <div className='border-neutral-2 flex flex-col border-b text-xs font-semibold'>
                    {uni.nodeOptions.map((option) => {
                      const selected = uni.name === uniName && nodeName === option.node.name
                      return (
                        <ComboboxOption
                          key={option.node.name}
                          value={option}
                          className={`data-[focus]:bg-information-1 border-neutral-2 flex w-full cursor-pointer items-center justify-between gap-2 border-t p-3 px-6`}
                        >
                          <div key={option.node.name}>{option.node.name}</div>
                          <div className='mr-2'>{selected ? <Icon name='check' size={16} stroke='blue' /> : null}</div>
                        </ComboboxOption>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <ComboboxOption
            value={{
              type: 'create-uni',
            }}
            className={`data-[focus]:bg-information-1 bg-uibg-1 border-neutral-4 flex cursor-pointer items-center justify-center rounded-b-md border-t p-6 text-sm font-bold`}
          >
            <Icon name='plus-m' size={14} className='mr-2' />
            Create Universal Application
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>
  )
}
