import { Organization } from '@vendia/management-api-types'
import { useContext } from 'react'
import { QueryClient, useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'

import { authContext } from '../auth/AuthContext'
import { selectedRoleState } from '../state'
import useApi from './use-api'

const QUERY_KEY = 'getOrg'
const FIVE_MINUTES = 300000

export const useGetOrg = ({ ...options } = {}) => {
  const { auth } = useContext(authContext)
  const selectedRoleName = useRecoilValue(selectedRoleState).name

  const api = useApi()
  return useQuery(
    [QUERY_KEY, selectedRoleName],
    async () => {
      const data = await api.getUserOrg<{ getOrganization?: Organization }>()
      if (data.errors) {
        throw new Error(JSON.stringify(data.errors))
      }
      return data
    },
    {
      staleTime: FIVE_MINUTES,
      enabled: auth.authenticated && !!selectedRoleName,
      ...options,
    },
  )
}

export const refetchOrg = ({ queryClient }: { queryClient: QueryClient }) => {
  return queryClient.invalidateQueries(QUERY_KEY, { refetchInactive: true })
}
