import Clipboard from 'clipboard'
import React, { useEffect, useRef } from 'react'

import styles from './copy.module.css'

interface CopyProps {
  text?: string
  children?: React.ReactNode
  className?: string
  onCopy?: (e: Clipboard.Event) => void
  element?: React.ReactElement<{ value: string }>
}

const Copy: React.FC<CopyProps> = ({ text, children, className, onCopy, element }) => {
  const copyElement = useRef<HTMLSpanElement | null>(null)
  const clipboardInstance = useRef<Clipboard | null>(null)

  useEffect(() => {
    if (!copyElement.current) return

    if (React.isValidElement(element)) {
      clipboardInstance.current = new Clipboard(copyElement.current, {
        text: () => element.props.value,
      })
    } else {
      clipboardInstance.current = new Clipboard(copyElement.current)
    }

    clipboardInstance.current.on('success', (e) => {
      if (onCopy && typeof onCopy === 'function') {
        onCopy(e)
      }
      e.clearSelection()
    })

    clipboardInstance.current.on('error', (e) => {
      console.error('Action:', e.action)
      console.error('Trigger:', e.trigger)
    })

    return () => {
      clipboardInstance.current?.destroy()
    }
  }, [element, onCopy])

  let copyText = text
  if (!copyText && children && typeof children === 'string') {
    copyText = children
  }

  return (
    <span className={styles.copy} ref={copyElement} data-clipboard-text={copyText}>
      {children}
    </span>
  )
}

export default Copy
