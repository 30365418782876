import { use } from 'chai'
import classNames from 'clsx'
import React, { ForwardedRef, MutableRefObject, useEffect, useRef, useState } from 'react'

import Copy from '../actions/copy'
import Icon from '../icons/icon'
import styles from './input.module.css'
import formValidation from './input.validation'

export type InputProps = {
  name: string
  /** Custom CSS Classes */
  className?: string
  /** Placeholder text */
  placeholder?: string
  /** Set type of HTML5 input */
  type?: string
  /** Set current value */
  value?: string
  /** Set initialValue for uncontrolled components */
  initialValue?: string
  /** visual styles */
  kind?: 'default'
  /** disable input field if true */
  isDisabled?: boolean
  /** make field required */
  isRequired?: boolean
  /** add icon into field */
  icon?: string
  /** Size of Icon. Takes number or pixel value. Example size={30} */
  iconSize?: string | number
  /** Show copy icon: Enables or disables a copy helper icon next to the input */
  isCopyable?: boolean
  /** field validation. Can be regex, function, or keyName from validation utils */
  validation?: string | Record<string, any> | ((value: string) => boolean)
  /** Custom Classname when input valid */
  validClassName?: string
  /** Custom Classname when input invalid */
  invalidClassName?: string
  /** Custom Error text */
  errorMessage?: string
  /** Custom Error text CSS class */
  errorMessageClassName?: string
  /** Run function onBlur */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>, value: string, isValid: boolean) => void
  /** Run function onChange */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string, isValid: boolean) => void
  /** Run function onClick */
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  /** Run function onFocus */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>, value: string, isValid: boolean) => void
  /** Run function onKeyPress */
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>, value: string, isValid: boolean) => void
  /** debounce validation timeout */
  debounce?: number
  /** Make textarea instead of input */
  isTextArea?: boolean
  /** (dont use unless necessry) Set to use outside state and disable debounce */
  isControlled?: boolean
  /** Is it read-only? **/
  readOnly?: boolean
  [key: string]: any
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      isDisabled,
      isRequired,
      validation,
      invalidClassName = styles.invalid,
      validClassName = styles.valid,
      errorMessage,
      errorMessageClassName,
      debounce = 100,
      initialValue,
      type = 'text',
      value,
      kind = 'default',
      icon,
      iconSize = 25,
      isCopyable,
      isControlled,
      onBlur,
      onClick,
      onChange,
      onFocus,
      onKeyPress,
      isTextArea,
      ...others
    }: InputProps,
    refIn: ForwardedRef<HTMLInputElement>,
  ) => {
    const textInput = (refIn as MutableRefObject<HTMLInputElement | null>) ?? useRef<HTMLInputElement | null>(null)
    const [isValid, setIsValid] = useState(false)
    const [blurRanOnce, setBlurRanOnce] = useState(false)
    const [uiErrorMessage, setUIErrorMessage] = useState(errorMessage)
    const [tid, setTid] = useState<number | undefined>()

    useEffect(() => {
      const ti = textInput?.current
      if (!ti) return

      // Set initialValue prop
      if (!ti.value && !value && initialValue) {
        ti.value = initialValue
      }

      setTimeout(() => {
        // sometimes value is set via the DOM. This updates initial state
        if (ti) {
          const value = ti.value
          if (value) {
            const inputData = doValidation(value)
            setIsValid(inputData.isValid)
            doVisibleValidation(inputData)
          }
        }
      }, 0)
    }, [textInput?.current])

    useEffect(() => {
      return () => {
        window.clearTimeout(tid)
      }
    }, [tid])

    const doValidation = (value: any) => {
      const defaultMessage = errorMessage || 'Invalid Value'
      if (typeof validation === 'string') {
        if (!formValidation[validation]) {
          throw new Error(`${validation} not valid validation prop. Must be one of ${Object.keys(formValidation)}`)
        }
        return {
          value,
          isValid: formValidation[validation].pattern.test(value),
          errorMessage: formValidation[validation].message || defaultMessage,
        }
      } else if (typeof validation === 'object' && validation.pattern) {
        return {
          value,
          isValid: validation.pattern.test(value),
          errorMessage: validation.message || defaultMessage,
        }
      } else if (validation instanceof RegExp) {
        return {
          value,
          isValid: validation.test(value),
          errorMessage: errorMessage || defaultMessage,
        }
      } else if (typeof validation === 'function') {
        return {
          value,
          isValid: validation(value),
          errorMessage: errorMessage || defaultMessage,
        }
      }
      return {
        value,
        isValid: true,
        errorMessage: '',
      }
    }

    const focus = () => {
      textInput?.current?.focus()
    }

    const doVisibleValidation = ({ isValid, value }: any) => {
      const input = textInput?.current
      if (validation && !isValid) {
        // has validation and is not valid!
        if (input?.value) {
          input?.classList.remove(validClassName)
          input?.classList.add(invalidClassName)
          // set fake blur so validation will show
          setBlurRanOnce(true)
          // show error message
          prompt()
        }
      } else if (validation && isValid) {
        // has validation and is valid!
        input?.classList.remove(invalidClassName)
        input?.classList.add(validClassName)
      }
      // If input is empty and the validation is bad, remove the valid class
      if (!value && !isValid) {
        input?.classList.remove(validClassName)
      }
    }

    const emitDelayedChange = (inputValue: string) => {
      const inputData = doValidation(inputValue)
      setTid(void 0)
      setIsValid(inputData.isValid)
      setUIErrorMessage(inputData.errorMessage)

      doVisibleValidation(inputData)

      if (onChange) {
        // because debounce, fake event is passed back
        const fakeEvent = { target: textInput?.current } as React.ChangeEvent<HTMLInputElement>
        onChange(fakeEvent, inputValue, inputData.isValid)
      }
    }

    const prompt = (cb?: (d: ReturnType<typeof doValidation>) => void) => {
      const inputData = doValidation(value)
      setTid(void 0)
      setIsValid(inputData.isValid)
      setUIErrorMessage(inputData.errorMessage)

      if (cb) {
        cb(inputData)
      }
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event, event.target.value, isValid)
      }

      if (event.target.value) {
        // only show if input has some value
        prompt((inputData) => {
          doVisibleValidation(inputData)
        })
      }

      if (!event.target.value) {
        textInput?.current?.classList.remove(validClassName)
      }

      // Set blur state to show validations
      if (!blurRanOnce && event.target.value) {
        // capture focus if input wrong
        setBlurRanOnce(true)
      }
    }

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
      if (onClick) {
        onClick(event)
      }
      if (textInput?.current?.value) {
        // make onClick 'trigger' a blur
        setBlurRanOnce(true)
      }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      // If has validation, apply debouncer
      let deboundTimeout = validation ? debounce : 0

      // If form values controlled by outside state ignore debounce
      if (isControlled) {
        deboundTimeout = 0
      }

      if (tid) {
        clearTimeout(tid)
      }

      const inputValue = event.target.value
      setTid(setTimeout(() => emitDelayedChange(inputValue), deboundTimeout) as any)
    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (others.readOnly) {
        textInput?.current?.select()
      }

      // this.outlineInput()
      if (onFocus) {
        onFocus(event, event.target.value, isValid)
      }
    }

    const showValidation = () => {
      if (isValid) {
        return null
      } else if (blurRanOnce) {
        const classes = classNames(styles.validation, errorMessageClassName)
        return (
          <div className={classes} onClick={focus}>
            <Icon name='error' size='xxs' />
            {'  '}
            {uiErrorMessage}
          </div>
        )
      }
    }

    const formatName = (name?: string) => name?.replace(/\s|-/g, '_')

    const classes = classNames(className, styles.input, styles[kind], {
      [styles.hasIcon]: icon,
    })

    const props = {
      ...others,
      onChange: handleChange,
      onBlur: handleBlur,
      onFocus: handleFocus,
      onClick: handleClick,
      role: 'input',
      name: others.name || others.id || others.textInput || formatName(others.placeholder),
      disabled: isDisabled,
      required: isRequired,
      type,
      value,
      className: classes,
    }

    let iconRender
    if (icon) {
      iconRender = (
        <div className={styles.iconWrapper}>
          <Icon onClick={focus} className={styles.icon} name={icon} size={iconSize} />
        </div>
      )
    }

    let tag = <input {...props} ref={textInput} />

    if (isTextArea) {
      tag = <textarea {...(props as any)} ref={textInput} />
    }

    const wrapperClasses = classNames(styles.inputWrapper, kind !== 'default' ? styles[`wrapper${kind}`] : false)

    return (
      <div className={wrapperClasses}>
        {iconRender}
        {tag}
        {showValidation()}
        {isCopyable && (
          <Copy className={styles.copyIcon} element={tag}>
            <Icon name={'copylink'} size={24} />
          </Copy>
        )}
      </div>
    )
  },
)

Input.displayName = 'Input'

export default Input
