// @ts-strict-ignore
import { User } from '@vendia/management-api-types'
import { useContext } from 'react'
import { useQuery } from 'react-query'

import { authContext } from '../auth/AuthContext'
import useApi from './use-api'

const QUERY_KEY = 'currentVendiaUser'

export default function useGetCurrentVendiaUserQuery({ ...options } = {}) {
  const { auth } = useContext(authContext)
  const api = useApi()
  const getCurrentVendiaUserQuery = useQuery(
    QUERY_KEY,
    async () => {
      const data = await api.getUser<{ getUser?: User }>()
      if (data.errors) {
        throw new Error(JSON.stringify(data.errors))
      }
      return data
    },
    {
      enabled: Boolean(auth.authenticated && api),
      staleTime: 1000 * 60 * 5, // 5 minutes
      ...options,
    },
  )

  return {
    getCurrentVendiaUserQuery,
  }
}

export function refetchCurrentVendiaUser({ queryClient }) {
  return queryClient.invalidateQueries(QUERY_KEY, {
    refetchInactive: true,
  })
}
