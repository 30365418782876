export function getPathArray(path: string | string[]): string[] {
  return Array.isArray(path) ? path.flatMap(getPathArray) : path.match(/([^[.\]])+/g) || []
}

export function setValue<T extends object = any>(obj: T, path: string | string[], value: any): T {
  const pathArray = getPathArray(path)
  const shallowCopy = { ...obj } as any

  pathArray?.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {}
    if (i === pathArray.length - 1) acc[key] = value
    return acc[key]
  }, shallowCopy)

  return shallowCopy
}

export function getValue<T extends object = any, V = any>(obj: T, path: string | string[]): V {
  return getPathArray(path)?.reduce((acc: any, key) => acc?.[key], obj)
}
