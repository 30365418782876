// @ts-strict-ignore
import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'

import { selectedRoleState } from '../state'
import useApi from './use-api'

const QUERY_KEY = 'listUnis'
const FIVE_MINUTES = 300000

export type ListUnisItem = {
  name: string
  sku: string
  status: string
  nodes: {
    name: string
    userId: string
    status: string
  }[]
}

export default function useListUnis({ isAuthenticated = true } = {}) {
  const api = useApi()
  const selectedRoleName = useRecoilValue(selectedRoleState).name
  const listUnisQuery = useQuery<ListUnisItem[], Error>([QUERY_KEY, selectedRoleName], () => api.listUnis(), {
    staleTime: FIVE_MINUTES,
    // Allow user/roles to load and logic in App.tsx to initialize selectedRole before firing
    // off first request to listUnis. Ensures we're requesting the correct unis and prevents
    // glitchy situation where app appears to finish loading, then pops back into loading state
    // (also breaks Cypress tests which think they can proceed before app is done loading)
    enabled: isAuthenticated && !!selectedRoleName,
  })

  return {
    listUnisQuery,
  }
}

export function refetchUnis({ queryClient }) {
  return queryClient.invalidateQueries(QUERY_KEY, {
    refetchInactive: true,
  })
}
